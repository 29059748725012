<bx-settings-breadcrumb [crumbs]="crumbs()"></bx-settings-breadcrumb>

@if (user(); as user) {
  <div class="bx-page-container bx-page-container-sm">
    @if (changeEmailResponse(); as response) {
      @if (!response.success) {
        <div class="alert alert-danger">
          {{ response.error }}
        </div>
      }
    }

    @if (!changeEmailResponse() || !changeEmailResponse().success) {
      <h3 class="mb-4">Change Email</h3>
      <form [formGroup]="form" (submit)="changeEmail()">
        <div class="mb-5">
          <label class="form-label" for="current-email">Current Email</label>
          <input
            id="current-email"
            type="email"
            class="form-control"
            [value]="user.email"
            data-spec="current-email"
            disabled
          />
        </div>

        <div class="mb-3">
          <label class="form-label" for="new-email">New Email</label>
          <input
            id="new-email"
            type="email"
            class="form-control"
            placeholder="Enter a new email"
            formControlName="newEmail"
            ngFormControlValidator
            [validateOnBlur]="true"
          />
          @if (newEmail.hasError('required')) {
            <div class="invalid-feedback">A new email is required.</div>
          } @else if (newEmail.hasError('email')) {
            <div class="invalid-feedback">"{{ newEmail.value }}" is not a valid email.</div>
          }
        </div>

        <div class="mb-3">
          <label class="form-label" for="confirm-email">Confirm Email</label>
          <input
            id="confirm-email"
            type="email"
            class="form-control"
            placeholder="Re-enter the new email"
            formControlName="confirmEmail"
            ngFormControlValidator
            [validateOnBlur]="true"
            [bxValidateEqual]="'newEmail'"
          />
          @if (confirmEmail.hasError('required')) {
            <div class="invalid-feedback">Please re-enter the new email.</div>
          } @else if (confirmEmail.hasError('validateEqual')) {
            <div class="invalid-feedback">Confirm email doesn't match new email.</div>
          }
        </div>

        <div class="d-flex w-100 justify-content-end">
          <button
            class="btn btn-primary me-2"
            bx-spinner-button
            [spin]="isChangingEmail()"
            [disabled]="form.invalid"
          >
            Change Email
          </button>
          <button class="btn btn-secondary" type="button" routerLink="/settings">Cancel</button>
        </div>
      </form>
    } @else if (changeEmailResponse().success) {
      @if (isUserProfileEmailChange()) {
        <h3>Email Change Requested</h3>
        <p>
          An activation link has been sent to your new email address. Please use this to confirm
          your email change.
        </p>
        <p>
          Your email address will not be changed until the activation process is complete. If you
          have any questions, please
          <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">contact support</a>.
        </p>
      } @else {
        <h3>Email Change Requested</h3>
        <p>
          An activation link has been sent to this user's new email address. They will need to use
          this link to confirm their email change.
        </p>
        <p>
          Their email address will not be changed until the activation process is complete. If you
          have any questions, please
          <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">contact support</a>.
        </p>
      }
    }
  </div>
}
